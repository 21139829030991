import React from 'react';
import { Redirect } from 'react-router-dom';

import config from '../../../config';

const NotFound = () => {
  return <Redirect to={config.indexPath} />
};

export default NotFound;
