import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BlankView from './View';

const Blank = ({ children, metaTitle }) => {
  useEffect(() => {
    document.title = metaTitle || '';
  }, [metaTitle]);

  return <BlankView children={children} />;
};

Blank.propTypes = {
  children: PropTypes.object.isRequired,
  metaTitle: PropTypes.string,
};

export default Blank;
