import { makeStyles } from '@material-ui/core';

const iconSize = "28px";

const Styles = theme => ({

  root: {
    "&, &.MuiPaper-root": {
      boxShadow: theme.boxShadowBase,
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.topBarHeight,
    },

    "& $drawerButton": {
      borderRadius: 0,
      margin: `0 ${theme.spacing(3)}px 0 ${theme.spacing(1)}px`,
      cursor: "pointer",
      height: iconSize,
      width: iconSize,
      padding: 0,

      "&:hover": {
        background: "transparent",
      },
    },

    "& $drawerButtonImage": {
      height: iconSize,
      width: iconSize,
    },

    "& $userButton": {
      borderRadius: 0,
      marginLeft: theme.spacing(3),
      cursor: "pointer",
      height: iconSize,
      width: iconSize,
      padding: 0,
    },

    "& $userButtonImage": {
      height: iconSize,
      width: iconSize,
    },

    "& $toolbar": {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]: {
        height: theme.topBarHeight,
        minHeight: theme.topBarHeight,
      },
    },

    "& $breadcrumbs": {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.typography.fontSizeBM,
      color: theme.colors.bodyTextSecondary,
    },

    "& $meta": {
      display: 'flex',
      alignItems: 'center',
    },
  },
  userMenu: {
    "& $userMenuItem": {
      display: "block",
      fontSize: theme.typography.fontSizeS,
      padding: "6px 16px",
    },
  },
  drawerButton: {},
  drawerButtonImage: {},
  userButton: {},
  helpButton: {
    marginRight: theme.spacing(2),
  },
  userButtonImage: {},
  userMenuItem: {},
  toolbar: {},
  breadcrumbs: {},
  meta: {},
  breadcrumbsLink: {
    paddingRight: theme.spacing(1),
    color: theme.colors.bodyTextSecondary,
  }
});

const TopBarStyles = makeStyles(Styles);

export default TopBarStyles;
