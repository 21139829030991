import React, { useCallback, useReducer, useMemo } from 'react';
import { ActionTypes, initialState, reducer } from './reducer';
import { getBasePathPrefix, localState } from '../utils';
import config from '../config';
import useAuth from '../auth/useAuth';

export const CoreContext = React.createContext({});

export const CoreContextProvider = (props) => {

  const [state, dispatch] = useReducer(reducer, initialState);

  const getIndexPath = useCallback(() => config.indexPath, []);

  const getAppName = useCallback(() => state.appName, [state.appName]);

  const switchApp = useCallback((appName, sidebarModules) => {
    dispatch({
      type: ActionTypes.SWITCH_APP,
      data: {
        appName,
        sidebarModules,
      },
    });
  }, []);

  const getActiveModule = useCallback(() => state.activeModule, [state.activeModule]);

  const setActiveModule = useCallback(module => {
    dispatch({
      type: ActionTypes.SET_ACTIVE_MODULE,
      data: {
        activeModule: module,
      },
    });
  }, []);

  const getAppAccess = useCallback(() => state.appAccess, [state.appAccess]);

  const setAppAccess = useCallback(access => {
    dispatch({
      type: ActionTypes.SET_APP_ACCEES,
      data: {
        access,
      },
    });
  }, []);

  const getAppActions = useCallback(() => state.appActions, [state.appActions]);

  const setAppActions = useCallback(actions => {
    dispatch({
      type: ActionTypes.SET_APP_ACTIONS,
      data: {
        actions,
      },
    });
  }, []);

  const getAppSwitching = useCallback(() => state.appSwitching, [state.appSwitching]);

  const setAppSwitching = useCallback(isSwitching => {
    dispatch({
      type: ActionTypes.SET_APP_SWITCHING,
      data: {
        isSwitching,
      },
    });
  }, []);

  const getSidebarOpen = useCallback(() => state.sidebarOpen, [state.sidebarOpen]);

  const setSidebarOpen = useCallback(() => {
    dispatch({
      type: ActionTypes.SET_SIDEBAR_OPEN,
    });
  }, []);

  const getSidebarOpenMobile = useCallback(() => state.sidebarOpenMobile, [state.sidebarOpenMobile]);

  const setSidebarOpenMobile = useCallback(() => {
    dispatch({
      type: ActionTypes.SET_SIDEBAR_OPEN_MOBILE,
    });
  }, []);

  const setToken = useCallback(({ jwtToken, jwtTokenExpiry }) => {
    dispatch({
      type: ActionTypes.UPDATE_TOKEN,
      data: {
        jwtToken,
        jwtTokenExpiry,
      },
    });
  }, []);

  const getToken = useCallback(() => {
    const JWTFromLocalStorage = localState().get('jwtToken') // in memory token is lost on refresh, fallback to localstorage in that case
    
    return {
      jwtToken: state.jwtToken || JWTFromLocalStorage,
      jwtTokenExpiry: state.jwtTokenExpiry || localState().get('jwtTokenExpiry'),
    }
  }, [state.jwtToken, state.jwtTokenExpiry]);

  const getSidebarModules = useCallback(() => state.sidebarModules, [state.sidebarModules]);

  const utilities = useMemo(() => ({
    useAuth,
    getAppName,
    getBasePathPrefix,
    getSidebarModules,
    getToken,
    setToken,
    getActiveModule,
    setActiveModule,
    getAppAccess,
    setAppAccess,
    getAppActions,
    setAppActions,
    getAppSwitching,
    setAppSwitching,
    getIndexPath,
    getSidebarOpen,
    setSidebarOpen,
    getSidebarOpenMobile,
    setSidebarOpenMobile,
    switchApp,
  }), [
    getAppName,
    getSidebarModules,
    getToken,
    setToken,
    getActiveModule,
    setActiveModule,
    getAppAccess,
    setAppAccess,
    getAppActions,
    setAppActions,
    getAppSwitching,
    setAppSwitching,
    getIndexPath,
    getSidebarOpen,
    setSidebarOpen,
    getSidebarOpenMobile,
    setSidebarOpenMobile,
    switchApp,
  ]);

  return (
    <CoreContext.Provider value={utilities}>
      {props.children}
    </CoreContext.Provider>
  );
}
