import { makeStyles } from "@material-ui/core";

const Styles = (theme) => ({

  root: {
    "& a": {
      textDecoration: "none",
      color: theme.colors.bodyTextSecondary,      
    },

    "& $icon": {
      borderRadius: "100%",
      position: "absolute",
      left: 8,
      width: `calc(${theme.drawerClosedWidth}px - 16px)`,
      minWidth: `calc(${theme.drawerClosedWidth}px - 16px)`,
      height: `calc(${theme.drawerClosedWidth}px - 16px)`,
      justifyContent: "center",
      alignItems: "center",
      border: "2px solid transparent",

      "& img": {
        width: "60%",        
      },
    },

    "& $item": {
      position: "relative",
      padding: theme.spacing(2),
      paddingLeft: theme.drawerClosedWidth,
      "&:hover, &:active, &:focus": {
        background: "none",
        transition: "none",
      }
    },

    "& $itemText": {
      fontSize: theme.typography.fontSizeBM,
    },

    "& $subItem": {
      padding: `0 0 0 ${theme.drawerClosedWidth}px`,
      justifyContent: "start",

      "&:hover, &:active, &:focus": {
        background: "none",
        transition: "none",
      }
    },

    "& $subItemList": {
      position: "relative",
      "&::before": {
        content: '""',
        position: "absolute",
        top: -10,
        left: 28,
        bottom: 10,
        width: 1,
        background: theme.colors.primary,
        zIndex: -1,
      },
      ".closed &": {
        display: "none !important",
      },
    },

    "& $subItemText": {
      background: "transparent",
      borderRadius: "1.5rem",
      display: "inline",
      color: theme.colors.bodyTextSecondary,
      flex: "none",
      fontSize: theme.typography.fontSizeS,
      padding: "5px 1rem",
      margin: "5px 0 5px -1rem",      
    },

    // active styles
    "& a.active": {
      "& $icon": {
        background: theme.colors.brandGradientLight,
        boxShadow: theme.boxShadowControls,
        border: "2px solid rgba(255,255,255,.3)",
      },
      "& $itemText": {
        color: theme.colors.bodyText,
        fontWeight: theme.typography.fontWeightBold,
      },
      "& $subItemText": {
        background: theme.colors.brandGradientLight,
        boxShadow: theme.boxShadowControls,
        color: theme.colors.bodyText,
        fontWeight: theme.typography.fontWeightBold, 
      },
    },

    // parent styles
    "&.parent": {
      "& $icon": {
        background: theme.colors.brandGradientLight,     
        border: "2px solid rgba(255,255,255,.3)",
      },
    },

    "& a.parent": {      
      "& $subItemText": {
        background: theme.colors.brandGradientLight,
        boxShadow: theme.boxShadowControls,
        color: theme.colors.bodyText,
        fontWeight: theme.typography.fontWeightBold, 
      },
    },

  },
  icon: {},
  item: {},
  itemText: {},
  subItem: {},
  subItemList: {},
  subItemText: {},
});

const NavListStyles = makeStyles(Styles);

export default NavListStyles;
