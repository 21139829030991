import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { AppBar, IconButton, Menu, MenuItem, Toolbar, Hidden } from '@material-ui/core';
import TopBarStyles from './styles';
import drawerIcon from '../../../../../assets/images/drawerIcon.svg';
import userIcon from '../../../../../assets/images/userIcon.svg';
import helpIcon from '../../../../../assets/images/Help.svg';
import { renderBreadcrumbs } from './helpers';

const TopBarView = ({ activeModule, handleDrawerOpen, handleLogout, handleUserMenu, userMenu, userName, handleHelpClick }) => {
  const classes = TopBarStyles();

  const history = useHistory();
 
  const breadcrumbs = activeModule.breadcrumbs ? (
    renderBreadcrumbs(activeModule.breadcrumbs, history, classes)
  ) : <p>{activeModule.metaTitle || ''}</p>;

  return (
    <AppBar position="sticky" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.breadcrumbs}>
          <Hidden smDown>
            <IconButton
              className={classes.drawerButton}
              edge="start"
              aria-label="open drawer"
              onClick={() => handleDrawerOpen('desktop')}
            >
              <img alt="" className={classes.drawerButtonImage} src={drawerIcon} />
            </IconButton>
          </Hidden>
          <Hidden mdUp>
            <IconButton
              className={classes.drawerButton}
              edge="start"
              aria-label="open drawer"
              onClick={() => handleDrawerOpen('mobile')}
            >
              <img alt="" className={classes.drawerButtonImage} src={drawerIcon} />
            </IconButton>
          </Hidden>
          {breadcrumbs}
        </div>
        <div className={classes.meta}>
          {handleHelpClick ? ( 
            <IconButton
              aria-controls="help"
              aria-haspopup="true"
              className={classes.helpButton}
              onClick={handleHelpClick}
            >
              <img alt="" className={classes.userButtonImage} src={helpIcon} />
            </IconButton> 
          ): null}
          
          <p>{userName}</p>
          <IconButton
            aria-controls="user-menu"
            aria-haspopup="true"
            className={classes.userButton}
            onClick={e => { handleUserMenu(e.target) }}
          >
            <img alt="" className={classes.userButtonImage} src={userIcon} />
          </IconButton>
          <Menu
            anchorEl={userMenu}
            className={classes.userMenu}
            id="user-menu"
            keepMounted
            open={userMenu !== null}
            onClose={() => { handleUserMenu(null) }}
          >
            <MenuItem className={classes.userMenuItem} onClick={() => handleLogout()}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

TopBarView.propTypes = {
  breadcrumbs: PropTypes.element,
  handleDrawerOpen: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default TopBarView;
