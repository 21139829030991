import childConfig from '../child/config';

export const getChildrenConfig = () => {
    return childConfig;
};

export const getChildConfigByName = (appName) => {
  switch(appName) {
    case 'AtHome':
      return getChildrenConfig()['atHomeConfig'];
    case 'Spark':
    default:
      return getChildrenConfig()['sparkConfig'];
  }
};