// Contains global styles and global mui overrides.
import { createMuiTheme } from "@material-ui/core/styles";

import themeLight from "./themeLight";
import themeDark from "./themeDark";

const themeChoice = localStorage.getItem("theme");

const theme = themeChoice === "dark" ? themeDark : themeLight;

const global = createMuiTheme({
  palette: {
    primary: {
      main: theme.colors.primary,
      contrastText: theme.colors.primaryContrastText,
    },
    secondary: {
      main: theme.colors.secondary,
      contrastText: theme.colors.secondaryContrastText,
    },
    success: {
      main: theme.colors.success,
      contrastText: theme.colors.successContrastText,
    },
    error: { main: theme.colors.error },
    warning: { main: theme.colors.warning },
    info: { main: theme.colors.info },
  },

  typography: {
    fontFamily: theme.typography.fontFamily,
    fontSizeBase: theme.typography.fontSizeBase,
    fontSizeHeading: theme.typography.fontSizeHeading,
    fontSizeS: theme.typography.fontSizeS,
    fontSizeBM: theme.typography.fontSizeBM,
    fontSizeM: theme.typography.fontSizeM,
    fontSizeML: theme.typography.fontSizeML,
    fontSizeL: theme.typography.fontSizeL,
    fontSizeTitle: theme.typography.fontSizeTitle,
    fontSizeXL: theme.typography.fontSizeXL,
    fontSizeXS: theme.typography.fontSizeXS,
    fontSizeXXL: theme.typography.fontSizeXXL,
    fontWeightBold: theme.typography.fontWeightBold,
    fontWeightLightBold: theme.typography.fontWeightLightBold,
    fontWeightRegular: theme.typography.fontWeightRegular,
    fontWeightSemiBold: theme.typography.fontWeightSemiBold,
    fontWeightThin: theme.typography.fontWeightThin,
    h1: {
      letterSpacing: theme.typography.noLetterSpacing,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.fontSizeTitle,
    },
    h2: {
      letterSpacing: theme.typography.noLetterSpacing,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.fontSizeHeading,
    },
    h3: {
      letterSpacing: theme.typography.noLetterSpacing,
      fontWeight: theme.typography.fontWeightSemiBold,
      fontSize: theme.typography.fontSizeXXL,
    },
    h4: {
      letterSpacing: theme.typography.noLetterSpacing,
      fontWeight: theme.typography.fontWeightSemiBold,
      fontSize: theme.typography.fontSizeXL,
      marginBottom: `${theme.spacing[3]}px`,
    },
    h5: {
      letterSpacing: theme.typography.noLetterSpacing,
      fontWeight: theme.typography.fontWeightLightBold,
      fontSize: theme.typography.fontSizeM,
    },
    h6: {
      letterSpacing: theme.typography.noLetterSpacing,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.fontSizeBase,
    },
    subtitle1: {
      color: theme.colors.subtitleTextColor,
      fontSize: theme.typography.fontSizeS,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    subtitle2: {
      color: theme.colors.subtitleTextColor,
      fontSize: theme.typography.fontSizeXS,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    body1: {
      fontSize: theme.typography.fontSizeBase,
      fontWeight: "inherit",
      color: theme.colors.bodyText,
    },
    body2: {
      fontSize: theme.typography.fontSizeS,
      fontWeight: "inherit",
      color: theme.colors.bodyText,
    },
  },

  colors: {
    primary: theme.colors.primary,
    alertBackground: theme.colors.alertBackground,
    alertText: theme.colors.alertText,
    bodyText: theme.colors.bodyText,
    bodyTextSecondary: theme.colors.bodyTextSecondary,
    boxBackground: theme.colors.boxBackground,
    brandGradientLight: theme.colors.brandGradientLight,
    brandGradientHeavy: theme.colors.brandGradientheavy,
    iconButtonText: theme.colors.iconButtonText,
    hrColor: theme.colors.hrColor,
  },

  spacing: theme.spacing,

  overrides: {

    MuiPaper: {
      root: {
        margin: 0,
      },
    },

    MuiGrid: {
      item: {
        display: "flex",
      },
    },

    MuiFormHelperText: {
      root: {
        fontSize: theme.typography.fontSizeXS,
        opacity: .7,
        margin: `${theme.spacing[2]}px 0`,
        paddingLeft: 13,

        "&$error": {
          color: theme.colors.error,
          opacity: 1,
        },
      },
    },

    MuiButton: {
      root: {
        borderRadius: 30,
        color: theme.colors.bodyText,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: theme.typography.fontWeightBold,
        padding: "6px 24px",
        fontSize: theme.typography.fontSizeS,
        textTransform: "none",
        minWidth: "84px",
        maxWidth: "300px",
        width: "auto",
        maxHeight: "44px",
        outline: 0,
        transition: theme.transitionBase,
        textDecoration: "none",

        "&, &.MuiButton-containedPrimary, &.MuiButtonBase-root": {
          backgroundColor: theme.colors.primary,
          borderRadius: 30,
          color: theme.colors.bodyText,
          padding: "6px 24px",
          minWidth: "84px",
          maxWidth: "300px",
          width: "auto",
          maxHeight: "44px",
          textDecoration: "none",
        },

        "&.MuiButton-outlinedPrimary": {
          color: theme.colors.bodyText,
          padding: "6px 24px",
          backgroundColor: theme.colors.buttonOutlinedPrimaryBackground,
          border: `2px solid ${theme.colors.buttonOutlinedPrimaryBorder}`,
          textDecoration: "none",
          "&:hover": {
            backgroundColor: theme.colors.buttonOutlinedPrimaryBorder,
            border: `2px solid ${theme.colors.buttonOutlinedPrimaryBorder}`,
          },
        },

        "&.MuiButton-outlinedSecondary": {
          color: theme.colors.bodyText,
          padding: "6px 24px",
          border: `2px solid ${theme.colors.secondary}`,
          fontWeight: theme.typography.fontWeightRegular,
          "&:hover": {
            backgroundColor: theme.colors.secondary,
            border: `2px solid ${theme.colors.secondary}`,
          },
        },

        "&.MuiButton-sizeSmall": {
          padding: "3px 9px",
        },

      },
      label: {
        whiteSpace: "nowrap",
      },

      contained: {
        boxShadow: "none",
      },
    },

    MuiIconButton: {
      color: theme.colors.iconButtonText,
      padding: "12px",
    },

    MuiRadio: {
      root: {
        "&$checked": {
          color: theme.colors.primary,
        },
      },
    },

    MuiLink: {
      root: {
        color: theme.colors.link,
        "&:visited": {
          color: theme.colors.link,
        }
      },
    },

    MuiOutlinedInput: {
      input: {
        padding: "14px",
      },
    },

    MuiAutocomplete: {
      inputRoot: {
        padding: "4px 9px 5px 9px !important",
      }
    },

    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 16px) scale(1)",
      },
    },

    MuiCheckbox: {
      colorPrimary: {        
        "&.MuiCheckbox-root": {
          color: theme.colors.bodyTextSecondary,
          "&$checked": {
            color: theme.colors.primary,
          },
        },        
      },
    },

    MuiSwitch: {
      switchBase: {
        "&, &.MuiSwitch-colorPrimary": {
          padding: 0,
          top: "unset",
          left: "2px",
          position: "absolute",
          color: theme.colors.switchColor,
          transition: "150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

          "&$checked + $track": {
            background: theme.colors.switchBackgroundCheckedColor,
            opacity: 1,
          },
        },
      },
      input: {
        width: "340%",
      },
      root: {
        display: "flex",
        alignItems: "center",
        borderRadius: "50%",
        width: "55px",
        opacity: 1,
        padding: 0,
        "&:before": {
          content: "Off",
          left: "6px",
          top: 0,
          position: "absolute",
          fontSize: "15px",
        },
      },
      colorPrimary: {
        "&$checked": {
          color: theme.colors.white,
          transform: "translateX(30px)",
        },
      },
      track: {
        height: "26px",
        backgroundColor: "#E4E4E4",
        opacity: 1,
        borderRadius: "30px",
      },
    },

    MuiTabs: {
      root: {
        backgroundColor: theme.colors.tabsContainerBackgroundColor,
        color: theme.colors.tabsTextColor,
      },
      indicator: {
        display: "none",
      },
    },

    MuiTab: {
      root: {
        backgroundColor: theme.colors.tabBackgroundColor,
        textTransform: "none",
        "&:hover": {
          backgroundColor: theme.colors.tabHoverBackgroundColor,
        },
      },
      textColorInherit: {
        opacity: 1,
        fontSize: theme.typography.tabTextFontSize,
        "&$selected": {
          color: theme.colors.tabSelectedTextColor,
          backgroundColor: theme.colors.tabSelectedBackgroundColor,
          fontSize: theme.typography.tabTextFontSize,
          fontWeight: theme.typography.fontWeightBold,
        },
      },
    },

    MuiSlider: {
      root: {
        height: "4px",
      },
      rail: {
        height: "4px",
        color: theme.colors.sliderRail,
        opacity: 1,
      },
      track: {
        background: theme.colors.sliderTrack,
        height: "4px",
      },
      thumb: {
        background: theme.colors.sliderThumb,
        width: "13px",
        height: "13px",
      },
    },

    MuiBreadcrumbs: {
      separator: {
        color: theme.colors.breadcrumbsSeparatorColor,
        fontSize: theme.typography.breadcrumbsSeparatorFontSize,
      },

      li: {
        color: theme.colors.breadcrumbsColor,
        fontSize: theme.typography.breadcrumbsFontSize,
        fontWeight: theme.typography.breadCrumbsFontWeight,

        "&:hover": {
          color: theme.colors.breadcrumbsHoverColor,
        },

        "& > p": {
          fontSize: theme.typography.breadcrumbsTextFontSize,
          color: theme.colors.breadcrumbsTextColor,
          fontWeight: theme.typography.breadCrumbsTextFontWeight,
        },
      },
    },

    MuiAppBar: {
      colorPrimary: {
        color: theme.appBar.color,
        backgroundColor: theme.appBar.backgroundColor,
        boxShadow: theme.boxShadowBase,
      },
    },

    MuiCard: {
      root: {
        boxShadow: theme.boxShadowBase,
        borderRadius: 0,
      },
    },

    MuiDialogTitle: {
      root: {
        fontSize: theme.typography.fontSizeL,
        fontWeight: theme.typography.fontWeightSemiBold,
      },
    },

    MuiDivider: {
      root: {
        border: `1px solid ${theme.colors.hrColor}`,
        margin: `${theme.spacing[3]}px ${theme.spacing[0]}px`,
      },
    },

    MuiTableCell: {
      root: {
        "&:first-child": {
          paddingLeft: 0,
        },
        "&:last-child": {
          paddingRight: 0,
        },
      },

      stickyHeader: {
        backgroundColor: "transparent",
      },
    },

    MuiTableFooter: {
      root: {
        "& .MuiTableCell-root": {
          border: "none",
        },
      },
    },

    MuiDialogContent: {
      root: {
        paddingBottom: `${theme.spacing[3]}px`,
      },
    },

    MuiList: {
      root: {
        "&, &.MuiMenu-list": {
          maxHeight: "400px",
        },
      },
    },
  },

  props: {
    MuiTextField: {
      variant: "outlined",
      InputLabelProps: {
        shrink: true,
      },
    },
    MuiButton: {
      color: "primary",
      variant: "contained",
    },
    MuiRadio: {
      color: "primary",
      disableRipple: true,
    },
    MuiSwitch: {
      color: "primary",
      disableTouchRipple: true,
    },
    MuiCheckbox: {
      color: "primary",
    },
    MuiTab: {
      disableTouchRipple: true,
    },
    MuiBreadcrumbs: {
      separator: ">",
    },
    MuiLink: {
      color: "inherit",
    },
    MuiDivider: {
      variant: "fullWidth",
    },
    MuiSelect: {
      variant: "outlined",
      MenuProps: {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        getContentAnchorEl: null,
      },
    },
  },

  outlinedLabel: {
    backgroundColor: theme.colors.outlinedLabelColor,
    color: theme.colors.white,
  },

  boxShadowBase: theme.boxShadowBase,
  boxShadowControls: theme.boxShadowControls,
  drawerWidth: theme.drawerWidth,
  drawerClosedWidth: theme.drawerClosedWidth,
  topBarHeight: theme.topBarHeight,
  topBarLineHeight: theme.topBarHeight,
  transitionBase: theme.transitionBase,
  sectionList: theme.sectionList,
  headingMargins: theme.headingMargins,
  hrColor: theme.colors.hrColor,
  secondaryHeadingFontWeight: theme.typography.fontWeightSemiBold,

  customToggleButton: {
    backgroundColor: theme.colors.lightGray2,
    labelColor: theme.colors.mediumGray,
    fontSize: theme.typography.fontSizeXS,
    activeBackgroundColor: theme.colors.primary,
    activeLabelColor: theme.colors.bodyText,
    activeFontSize: theme.typography.fontSizeS,
    hoverBackgroundColor: theme.colors.gray,
  },
});

export default global;
