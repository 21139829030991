import { paths } from '../../constants';
import { AtHomeApp } from './index';
import atHomeLogo from '../../assets/images/atHomeLogo.png';

const config = {
  appIcon: atHomeLogo,
  appName: 'AtHome',
  appTitle: 'At Home Dashboard',
  appPath: paths.APPS.AT_HOME,
  modules: [
    {
      id: 'athome-dashboard',
      component: AtHomeApp,
      label: 'At Home Dashboard',
      metaTitle: 'At Home Dashboard',
      path: paths.APPS.AT_HOME,
      isPublic: false,
      exact: false,
    }
  ],
};

export default config;