import React, { useEffect, useState } from 'react';
import MicroFrontendStyles from "./styles";

const MicroFrontend = (props) => {

  const [childMounted, setChildMounted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const classes = MicroFrontendStyles();

  useEffect(() => {
    const { name, host } = props;
    const scriptId = `micro-frontend-script-${name}`;
    const stylesId = 'childStyles';

    if (document.getElementById(scriptId)) {
      renderMicroFrontend();
      return;
    }

    fetch(`${host}/asset-manifest.json`)
      .then(res => res.json())
      .then(manifest => {
        if (manifest.files['main.css']) {
          const prevChildStyles = document.getElementById(stylesId);

          // if some child styles loaded before, we remove it first
          if (prevChildStyles) {
            prevChildStyles.remove();
          }

          // then we re-reference the most current child styles
          const styles = document.createElement('link');
          styles.href = `${host}${manifest.files['main.css']}`;
          styles.id = stylesId;
          styles.rel = 'stylesheet';
          document.head.appendChild(styles);
        }

        const script = document.createElement('script');
        script.id = scriptId;
        script.src = `${host}${manifest.files['main.js']}`;
        script.onload = renderMicroFrontend;
        document.head.appendChild(script);

      })
      .catch((err) => {
        console.log(err);
        handleError();
      });

    return () => {
      const { name } = props;
      childMounted && window[`unmount${name}`](`${name}-container`);
      setChildMounted(false);
    };

  }, []); 

  const handleError = () => {
    setErrorMessage('The application did not load properly. Please log out and try logging in again or contact support.');
    setChildMounted(false);
  };

  const renderMicroFrontend = () => {
    const { name, history, coreUtils, theme } = props;
    window[`render${name}`](`${name}-container`, { history, coreUtils, theme, name });
    setChildMounted(true);
  };

  return <div id={`${props.name}-container`}>
    {errorMessage && (<div className={classes.formMessage}>{errorMessage}</div>)}
  </div>;

};

export default MicroFrontend;

MicroFrontend.defaultProps = {
  document,
  window,
};