import { makeStyles } from "@material-ui/core";

const Styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "& $header": {
      display: "flex",
      alignItems: "center",
      height: theme.topBarHeight,
    },
    "& $logo": {
      margin: `0 ${theme.spacing(2)}px`,
      height: "42px",
      width: "42px",

      "& img": {
        display: "block",
        height: "100%",
        width: "auto",  
      }
    },
    "& $title": {
      flexGrow: 1,
      fontSize: theme.typography.fontSizeBM,
      lineHeight: theme.topBarLineHeight,
      margin: 0,
      whiteSpace: "nowrap",
    },
    "& $list": {
      flexGrow: 1,
      maxHeight: "none",
      overflowY: "auto",
      padding: 0,
    },
    "& $progress": {
      background: "#fff",
      position: "absolute",
      top: 0,
      left: 0,
      padding: theme.spacing(2),

    },
  },
  header: {},
  logo: {},
  title: {},
  list: {},
  progress: {},
});

const SideBarStyles = makeStyles(Styles);

export default SideBarStyles;
