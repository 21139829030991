import { makeStyles } from '@material-ui/core/styles';
import theme from '../theme';

const Styles = () => ({
  formMessage: {
    color: "#fff",
    borderRadius: 4,
    background: theme.palette.error.main,
    marginBottom: theme.spacing(3),
    padding: theme.spacing(4),
  },

});

const MicroFrontendStyles = makeStyles(Styles);

export default MicroFrontendStyles;
