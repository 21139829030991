import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { CoreContext } from '../../../context/CoreContext';

import { resetPassword } from '../../../auth';

import ResetPasswordView from './View';

const ResetPassword = () => {
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('error');
  let { token } = useParams();

  const success = response => {
    const _message = (response.status !== 200) ? 'Something went wrong with your request' : 'Your password has been successfully updated';
    const _messageType = (response.status !== 200) ? 'error' : 'success';
    setMessage(_message);
    setMessageType(_messageType);
  };

  const error = (response) => {
    const _message = 'There has been an error with your request';
    setMessage(_message);
    setMessageType('error');
  };

  const onSubmit = formData => {
    setMessage('');
    resetPassword({
      password: formData.password,
      token: token,
    }).then(response => {
      !response.error ? success(response) : error(response);
    });
  };

  return <ResetPasswordView message={message} messageType={messageType} handleResetPasswordSubmit={onSubmit} />;
};

export default ResetPassword;