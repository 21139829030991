import React, { useContext, useEffect } from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';

import config from '../config';
import { getChildrenConfig } from '../utils';
import RouterWrapper from './RouterWrapper';
import NotFound from '../components/pages/NotFound';
import { CoreContext } from '../context/CoreContext';

const Routes = () => {
  const childrenConfig = getChildrenConfig();
  const coreConfig = config;
  const context = useContext(CoreContext);
  const appName = context.getAppName() || ''; 

  useEffect(() => {
    if (appName === 'Spark' && typeof document !== "undefined") {
      const gtmScript = document.createElement("script");
      gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-59FPL2F');`;

      const gtmNoScript = document.createElement("noscript");
      const iframe = document.createElement("iframe");
      iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-59FPL2F";
      iframe.height = "0";
      iframe.width = "0";
      iframe.style = "display:none;visibility:hidden";
      gtmNoScript.appendChild(iframe);

      document.head.appendChild(gtmScript);
      document.body.appendChild(gtmNoScript);
    }
  }, [appName]);

  let pages = [];

  coreConfig.pages.forEach((page) => {
    pages = [...pages, page];
  });

  Object.values(childrenConfig).forEach((childConfig) => {
    childConfig.modules.forEach((page) => {
      pages = [...pages, page];
    });
  });

  const routeComponents = pages.map((page) => {
    return (
      <RouterWrapper
        key={page.id}
        component={page.component}
        exact={page.exact != null ? page.exact : true}
        isPublic={page.isPublic || false}
        layout={page.layout}
        metaTitle={page.metaTitle || ''}       
        path={page.path}
      />
    );
  });
  return (
    <BrowserRouter>
      <Switch>
        {routeComponents}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
