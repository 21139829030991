import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Router from './router';
import theme from './theme';
import { CoreContextProvider } from './context/CoreContext';

function App() {

  return (
    <ThemeProvider key='core' theme={theme}>
      <CssBaseline />
      <CoreContextProvider>
        <Router />
      </CoreContextProvider>
    </ThemeProvider>
  );
}

export default App;
