import { localState, getChildConfigByName } from './';
import { localStateKeys } from '../constants';

const getActiveAppPath = appAccess => {

    const atHomeAccess = appAccess['AtHome'];
    const sparkAccess = appAccess['Spark'];

    const atHomePath = atHomeAccess ? getChildConfigByName('AtHome').appPath : '';
    const sparkPath = sparkAccess ? getChildConfigByName('Spark').appPath : '';

    const localActiveApp = getChildConfigByName(localState().get(localStateKeys.ACTIVE_APP));
    const localActiveAppPath = localActiveApp.appPath || '';

    if (localActiveAppPath !== '' && atHomeAccess && sparkAccess) return localActiveAppPath;
    if (sparkPath !== '') return sparkPath;
    if (atHomePath !== '') return atHomePath;
    return '';
    
};

export default getActiveAppPath;