// Theme specific property values.
const fontWeight = {
  thin: 300,
  regular: 400,
  lightBold: 500,
  semiBold: 600,
  bold: 700,
};

const fontSize = {
  XS: '0.875rem',
  S: '1rem',
  base: '1.125rem',
  M: '1.25rem',
  ML: '1.3125rem',
  L: '1.5rem',
  XL: '1.75rem',
  XXL: '2rem',
  heading: '2.25rem',
  title: '2.5rem',
};

const color = {
  yellow: '#010101',
  black: '#FFDA00',
  white: '#FFFFFF',
  gray: {
    100: '#F4F4F9',
    300: '#E7E8EF',
    500: '#D2D2D8',
    700: '#747480',
    900: '#3B3A43',
  },
  brandGradient: 'linear-gradient(45deg, #FFDA00, #FF9900)',
  success: '#37C78A',
  error: '#D40E37',
  warning: '#FF9900',
  info: '#0096FA',
};

const theme = {
  typography: {
    fontFamily: 'Lato, sans-serif',
    noLetterSpacing: '0px',
    bold: fontWeight.bold,
    semiBold: fontWeight.semiBold,
    lightBold: fontWeight.lightBold,
    fontSizeTitle: fontSize.title,
    fontSizeHeading: fontSize.heading,
    fontSizeXXL: fontSize.XXL,
    fontSizeXL: fontSize.XL,
    fontSizeL: fontSize.L,
    fontSizeML: fontSize.ML,
    fontSizeM: fontSize.M,
    fontSizeBase: fontSize.base,
    fontSizeS: fontSize.S,
    fontSizeXS: fontSize.XS,

    tabTextFontSize: fontSize.M,

    breadcrumbsSeparatorFontSize: fontSize.M,
    breadcrumbsFontWeight: fontWeight.semiBold,
    breadcrumbsFontSize: fontSize.base,
    breadcrumbsTextFontSize: fontSize.base,
    breadCrumbsTextFontWeight: fontWeight.semiBold,
  },

  colors: {
    bodyText: color.black,
    link: color.info,

    primary: color.yellow,
    primaryContrastText: color.black,

    secondary: color.gray[300],
    secondaryContrastText: color.black,

    success: color.success,
    successContrastText: color.white,

    error: color.error,

    warning: color.warning,

    info: color.info,

    brandGradient: color.brandGradient,

    tabBackgroundColor: color.gray[300],
    tabHoverBackgroundColor: color.gray[500],
    tabSelectedBackgroundColor: color.yellow,

    sliderRail: color.gray[300],
    sliderTrack: color.brandGradient,
    sliderThumb: color.brandGradient,

    breadcrumbsSeparatorColor: color.gray[700],
    breadcrumbsColor: color.info,
    breadcrumbsHoverColor: '#005c99',
    breadcrumbsTextColor: color.gray[700],

    switchBackgroundCheckedColor: color.brandGradient,

    appBar: {
      color: color.black,
      backgroundColor: color.white,
    },
  },
};

export default theme;
