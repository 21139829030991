import { useContext, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import config from '../config';
import { localState } from '../utils';
import { localStateKeys } from '../constants';
import { CoreContext } from '../context/CoreContext';

const useAuth = () => {
    const context = useContext(CoreContext);
    const history = useHistory();

    // TODO: handle logout error
    const logout = useCallback(async () => {
        await fetch(`${process.env.REACT_APP_LOGIN_API_URL}/logout`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
            }
        });

        localState().set(localStateKeys.APP_ACCESS, {});
        localState().set(localStateKeys.APP_ACTIONS, {});
        localState().set(localStateKeys.JWT_TOKEN, '');
        localState().set(localStateKeys.JWT_TOKEN_EXPIRY, '');


        context && context.setToken({ jwtToken: null, jwtTokenExpiry: null });
        history && history.push(config.indexPath);
    }, [context, history]);

    const checkLoginExpiration = useCallback(() => {
        const tokenExpiry = context.getToken().jwtTokenExpiry;

        const currentDate = new Date();
        if (tokenExpiry && (tokenExpiry - (currentDate.getTime() / 1000) < 0)) {
            logout();
        }
    }, [context, logout]);

    return {
        logout,
        checkLoginExpiration,
    }
}

export default useAuth;