import React from 'react';
import PropTypes from 'prop-types';
import BlankStyles from './styles';

const BlankView = ({ children }) => {
  const classes = BlankStyles();
  
  return (
    <div className={classes.container}>
      {children}
    </div>
  );
};

BlankView.propTypes = {
  children: PropTypes.object.isRequired,  
};

export default BlankView;
