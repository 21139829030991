import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { localState } from '../../../../../utils';
import { Collapse, List, ListItemText, ListItem, ListItemIcon } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { localStateKeys } from '../../../../../constants';

import NavItemIcon from '../../../../../assets/images/NavItemIcon.png';
import NavListStyles from "./styles";

const NavListView = ({ activeModule, appActions, appName, modules, isSidebarOpen, variant }) => {
  const classes = NavListStyles();

  const getModuleAccess = id => {
    if (appName !== 'Spark') return true;

    const moduleAction = appActions[id];
    if (moduleAction !== undefined) {
      const access = moduleAction.access;
      if (!access) return false;
    } else {
      return false;
    }
    return true;
  };

  const NavListSubItem = ({ path, label, id }) => {
    const activeModuleId = activeModule.id || '';
    const activeModuleParents = activeModule.parents || [];

    const activeClassName = activeModuleId === id ? 'active' : '';
    const parentClassName = activeModuleParents.includes(id) ? 'parent' : '';

    return (
      <Link className={`${activeClassName} ${parentClassName}`} to={path}>        
        <ListItem button disableRipple className={classes.subItem}>
          <ListItemText primary={label} disableTypography className={classes.subItemText} />
        </ListItem>
      </Link>
    )
  };

  const NavListSubItems = ({ children, expanded }) => {
    return (
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.subItemList}>
          {children.filter((child) => getModuleAccess(child.id)).map((item, key) => <NavListSubItem key={key} label={item.label} path={item.path} id={item.id} />)}
        </div>
      </Collapse>
    )
  };

  const NavListItem = ({ module }) => {
    const children = module.children ? module.children.filter(child => !child.navExclude) : [];
    const path = module.path || (module.children ? module.children[0].path : '');
    const localStateKey = `${localStateKeys.SIDEBAR_NAV_EXPANDED}${module.id}`;
    const localStateValue = localState().get(localStateKey);
    const [expanded, setExpanded] = useState(localStateValue || false);
    const id = module.id;

    const handleClick = e => {
      if (children.length > 0) {
        if (!isSidebarOpen && variant === 'desktop') {
          setExpanded(true);
          localState().set(localStateKey, true);
        } else {
          e.preventDefault();
          setExpanded(!expanded);
          localState().set(localStateKey, !expanded);
        }      
      }
    };

    const activeModuleId = activeModule.id || '';
    const activeModuleParents = activeModule.parents || [];
    
    const expandedClassName = expanded ? 'expanded' : '';
    const parentClassName = activeModuleParents.includes(id) ? 'parent' : '';

    const icon = module.icon ? module.icon : NavItemIcon;

    return (
      <div className={`${classes.root} ${expandedClassName} ${parentClassName}`}>
        <Link 
          className={activeModuleId === id ? 'active' : ''} 
          to={path} 
          onClick={handleClick}
        >
          <ListItem button disableRipple className={classes.item}>
            <ListItemIcon className={classes.icon}><img src={icon} alt="" /></ListItemIcon>            
            <ListItemText className={classes.itemText} disableTypography primary={module.label} />
            {(children.length > 0) && (expanded ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
        </Link>
        {(children.length > 0) && (
          <NavListSubItems expanded={expanded} children={children} />
        )}
      </div>
    )
  };

  return (
    <List>
      {modules.filter((module) => !module.navExclude && getModuleAccess(module.id)).map((module) => <NavListItem module={module} key={module.id} />)}
    </List>
  );

};

export default NavListView;
