const localStateKeys = {
  ACTIVE_APP: 'activeApp',
  APP_ACTIONS: 'appActions',
  APP_ACCESS: 'appAccess',
  JWT_TOKEN: 'jwtToken',
  JWT_TOKEN_EXPIRY: 'jwtTokenExpiry',
  SIDEBAR_NAV_EXPANDED: 'sideBarNavExpanded',
  SIDEBAR_OPEN: 'sidebarOpen',

};

export default localStateKeys;
