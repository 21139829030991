import { paths } from './constants';
import { pages } from './components/pages';

const config = {
  indexPath: paths.LOGIN,
  helpPath: paths.HELP,
  pages: [    
    {
      id: 'aaaf372e-23fd-11eb-adc1-0242ac120002',
      component: pages.ForgotPassword,
      isPublic: true,
      label: 'Forgot Password',
      layout: 'centered',
      metaTitle: 'Forgot Password',
      path: paths.FORGOT_PASSWORD,
    },
    {
      id: 'b221ea2e-23fd-11eb-adc1-0242ac120002',
      component: pages.Login,
      isPublic: true,
      label: 'Login',
      layout: 'centered',
      metaTitle: 'Login',
      path: paths.LOGIN,
    },
    {
      id: 'aaaf372e-23fd-11eb-adc1-0242ac120202',
      component: pages.ResetPassword,
      isPublic: true,
      label: 'Reset Password',
      layout: 'centered',
      metaTitle: 'Reset Password',
      path: paths.RESET_PASSWORD,
    },
  ]
};

export default config;
