import React, { useState } from "react";
import PropTypes from "prop-types";
import {  
  Button,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { Link as RouterLink }  from "react-router-dom";

import CenteredStyles from "../../../layouts/Centered/View/styles";
import { paths } from "../../../../constants";
import dickeysLogo from "../../../../assets/images/dickeysLogo.png";

const LoginView = ({ message, handleLoginSubmit }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const classes = CenteredStyles();
  return (
    <CardContent className={classes.cardContent}>
      <Grid item className={classes.title} xs={6}>
        <img alt="Dickey's logo" src={dickeysLogo} />
        <Typography component="h1" variant="h4">Spark Dashboard</Typography>
      </Grid>
      <form className={classes.form} onSubmit={handleSubmit(handleLoginSubmit)}>
        {message !== '' && (<div className={classes.formError}>{message}</div>)}
        <FormControl className={classes.formControl}>
          <TextField
            error={!!errors.email}
            name="email"
            type="email"
            placeholder="email@dickeys.com"
            fullWidth
            label="Email"
            inputRef={register({ required: true })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {errors.email && <FormHelperText>Email is required</FormHelperText>}
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            error={!!errors.password}
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="*********"
            label="Password"
            fullWidth
            inputRef={register({ required: true })}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.password && (
            <FormHelperText>Password is required</FormHelperText>
          )}
        </FormControl>
        <Grid item xs={12}>
          <Grid container alignItems="center" justify="space-between">
            <Button type="submit">LOG IN</Button>
            <Link component={RouterLink} to={paths.FORGOT_PASSWORD}>Forgot your password?</Link>
          </Grid>
        </Grid>
      </form>
    </CardContent>
  );
};

LoginView.propTypes = {
  handleLoginSubmit: PropTypes.func.isRequired,
};

export default LoginView;
