import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CoreContext } from '../context/CoreContext';

import { Blank, Centered, WithSideBar } from '../components/layouts';

import useAuth from "../auth/useAuth";
import { useInterval } from "../hooks";

const REFRESH_INTERVAL = 10000; // ms

const RouterWrapper = ({ component: Component, exact, isPublic, layout, metaTitle, path }) => {
  const context = useContext(CoreContext);

  const { jwtToken } = context.getToken();

  const { checkLoginExpiration } = useAuth();

  useInterval(
    checkLoginExpiration,
    REFRESH_INTERVAL
  );

  let Layout = null;

  switch (layout) {
    case "blank":
      Layout = Blank;
      break;
    case "centered":
      Layout = Centered;
      break;
    default:
      Layout = WithSideBar;
  }

  // useEffect(() => {
  //   !isPublic && (async () => {
  //     setLoggedIn(await isLoggedIn(context));
  //   })();
  //   return () => { };
  // }, [])

  const renderRoute = (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        return (
          <Layout metaTitle={metaTitle}>
            <Component {...props} />
          </Layout>
        );
      }}
    ></Route>
  );

  if (isPublic) return renderRoute;

  return jwtToken && renderRoute;

};

RouterWrapper.propTypes = {
  component: PropTypes.func.isRequired,
  exact: PropTypes.bool,
  isPublic: PropTypes.bool,
  layout: PropTypes.string,
  metaTitle: PropTypes.string,
  path: PropTypes.string.isRequired,
};

export default RouterWrapper;
