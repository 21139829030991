const localState = () => {

    const localStateObjectKey = 'localState';

    const getLocalStateObject = () => {
        return JSON.parse(localStorage.getItem(localStateObjectKey)) || {};
    };

    const get = key => {        
        const localStateObject = getLocalStateObject();
        return localStateObject[key] !== undefined ? localStateObject[key] : null;
    };

    const set = (key, value) => {
        const localStateObject = getLocalStateObject();
        localStateObject[key] = value;
        localStorage.setItem(localStateObjectKey, JSON.stringify(localStateObject));
    };

    return { get, set }

};

export default localState;