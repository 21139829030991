import { useTheme } from '@material-ui/core';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import MicroFrontend from '../MicroFrontend';
import { CoreContext } from '../../context/CoreContext';

/**
 * This is the high level wrapper to pass various context values to the Microfrontends.
 * @param {host, appName} param0 
 */
export const MicroFrontendConsumer = ({ host, appName }) => {
  const utilities = useContext(CoreContext)
  const theme = useTheme();
  const history = useHistory();

  return (
    <MicroFrontend
      coreUtils={utilities}
      history={history}
      theme={theme}
      host={host}
      name={appName}
    />
  );

}