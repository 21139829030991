import { localState } from '../utils';
import { localStateKeys } from '../constants';

export const ActionTypes = {
  APP_ACCESS: 'APP_ACCESS',
  APP_ACTIONS: 'APP_ACTIONS',
  SWITCH_APP: 'SWITCH_APP',
  SET_ACTIVE_MODULE: 'SET_ACTIVE_MODULE',
  SET_APP_ACTIONS: 'SET_APP_ACTIONS',
  SET_APP_SWITCHING: 'SET_APP_SWITCHING',
  UPDATE_TOKEN: 'UPDATE_TOKEN',
  SET_SIDEBAR_OPEN: 'SET_SIDEBAR_OPEN',
  SET_SIDEBAR_OPEN_MOBILE: 'SET_SIDEBAR_OPEN_MOBILE',
}

export const initialState = {
  appAccess: localState().get(localStateKeys.APP_ACCESS) || {},
  appActions: localState().get(localStateKeys.APP_ACTIONS) || {},
  appName: '',
  appSwitching: false,
  activeModule: {},
  jwtToken: '',
  jwtTokenExpiry: '',
  sidebarModules: [],
  sidebarOpen: (localState().get(localStateKeys.SIDEBAR_OPEN) !== undefined) ? localState().get(localStateKeys.SIDEBAR_OPEN) : true,
  sidebarOpenMobile: false,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SWITCH_APP:
      localState().set(localStateKeys.ACTIVE_APP, action.data.appName);
      return {
        ...state,
        appName: action.data.appName,
        appSwitching: false,
        sidebarModules: [...action.data.sidebarModules],
      };
    case ActionTypes.SET_APP_ACCESS:
      localState().set(localStateKeys.APP_ACCESS, action.data.access);
      return {
        ...state,
        appAccess: action.data.access,
      };
    case ActionTypes.SET_APP_ACTIONS:
      localState().set(localStateKeys.APP_ACTIONS, action.data.actions);
      return {
        ...state,
        appActions: action.data.actions,
      };
    case ActionTypes.SET_APP_SWITCHING:
      return {
        ...state,
        appSwitching: action.data.isSwitching,
      };
    case ActionTypes.SET_ACTIVE_MODULE:
      return {
        ...state,
        activeModule: action.data.activeModule,
      };
    case ActionTypes.SET_SIDEBAR_OPEN:
      localState().set(localStateKeys.SIDEBAR_OPEN, !state.sidebarOpen);
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
      };
    case ActionTypes.SET_SIDEBAR_OPEN_MOBILE:
      return {
        ...state,
        sidebarOpenMobile: !state.sidebarOpenMobile,
      };
    case ActionTypes.UPDATE_TOKEN:
      localState().set(localStateKeys.JWT_TOKEN, action.data.jwtToken);
      localState().set(localStateKeys.JWT_TOKEN_EXPIRY, action.data.jwtTokenExpiry);
      return {
        ...state,
        jwtToken: action.data.jwtToken,
        jwtTokenExpiry: action.data.jwtTokenExpiry,
      };
    default:
      return state;
  }
}