// Theme specific property values.
const fontWeight = {
  thin: 300,
  regular: 400,
  lightBold: 500,
  semiBold: 600,
  bold: 700,
};

const fontSize = {
  XS: "0.875rem",
  S: "1rem",
  base: "1rem",
  BM: "1.125rem",
  M: "1.25rem",
  ML: "1.3125rem",
  L: "1.5rem",
  XL: "1.75rem",
  XXL: "2rem",
  heading: "2.25rem",
  title: "2.5rem",
};

const color = {
  yellow: "#FFDA00",
  blue: "#005c99",
  black: "#010101",
  white: "#FFFFFF",
  gray: {
    100: "#F4F4F9",
    300: "#E7E8EF",
    500: "#D3D3DB",
    700: "#747480",
    900: "#3B3A43",
  },
  brandGradientLight: "linear-gradient(45deg, #ffc000, #ffda00)",
  brandGradientHeavy: "linear-gradient(45deg, #FFDA00, #FF9900)",
  success: "#37C78A",
  error: "#D40E37",
  warning: "#FF9900",
  info: "#0096FA",
};

const spacing = [0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80];

const theme = {
  typography: {
    fontFamily: "Lato, sans-serif",
    fontSizeBase: fontSize.base,
    fontSizeHeading: fontSize.heading,
    fontSizeL: fontSize.L,
    fontSizeM: fontSize.M,
    fontSizeML: fontSize.ML,
    fontSizeBM: fontSize.BM,
    fontSizeS: fontSize.S,
    fontSizeTitle: fontSize.title,
    fontSizeXL: fontSize.XL,
    fontSizeXS: fontSize.XS,
    fontSizeXXL: fontSize.XXL,
    fontWeightBold: fontWeight.bold,
    fontWeightLightBold: fontWeight.lightBold,
    fontWeightRegular: fontWeight.regular,
    fontWeightSemiBold: fontWeight.semiBold,
    fontWeightThin: fontWeight.thin,
    noLetterSpacing: "0px",

    tabTextFontSize: fontSize.base,

    breadcrumbsFontSize: fontSize.base,
    breadcrumbsFontWeight: fontWeight.semiBold,
    breadcrumbsSeparatorFontSize: fontSize.M,
    breadcrumbsTextFontSize: fontSize.base,
    breadCrumbsTextFontWeight: fontWeight.semiBold,
  },

  colors: {
    alertBackground: color.error,
    alertText: color.white,
    bodyText: color.black,
    bodyTextSecondary: color.gray[700],
    boxBackground: color.white,
    iconButtonText: color.gray[700],
    white: color.white,
    link: color.blue,

    primary: color.yellow,
    primaryContrastText: color.black,

    secondary: color.gray[300],
    secondaryContrastText: color.black,

    success: color.success,
    successContrastText: color.white,

    error: color.error,

    warning: color.warning,

    info: color.info,

    brandGradientLight: color.brandGradientLight,
    brandGradientHeavy: color.brandGradientHeavy,

    buttonOutlinedPrimaryBackground: color.white,
    buttonOutlinedPrimaryBorder: color.yellow,  

    lightGray: color.gray[100],
    lightGray2: color.gray[300],
    gray: color.gray[500],
    mediumGray: color.gray[700],
    darkGray: color.gray[900],

    tabsTextColor: color.gray[900],
    tabsContainerBackgroundColor: color.gray[300],
    tabBackgroundColor: color.gray[300],
    tabHoverBackgroundColor: color.gray[500],
    tabSelectedBackgroundColor: color.yellow,
    tabSelectedTextColor: color.black,

    sliderRail: color.gray[300],
    sliderTrack: color.brandGradientHeavy,
    sliderThumb: color.brandGradientHeavy,

    breadcrumbsSeparatorColor: color.gray[700],
    breadcrumbsColor: color.info,
    breadcrumbsHoverColor: color.blue,
    breadcrumbsTextColor: color.gray[700],

    switchColor: color.gray[100],
    switchBackgroundCheckedColor: color.brandGradientHeavy,

    subtitleTextColor: color.gray[700],

    hrColor: color.gray[500],

    outlinedLabelColor: color.gray[700],
  },
  appBar: {
    color: color.black,
    backgroundColor: color.white,
  },

  spacing,

  sectionList: {
    color: color.gray[700],
    fontSize: fontSize.base,
    selected: {
      backgroundColor: `${color.white} !important`,
      color: color.black,
      boxShadow: "5px 5px 11px #00000014",
      borderLeft: "4px solid",
      borderImageSource: color.brandGradientHeavy,
      borderImageSlice: 1,
      fontWeight: fontWeight.bold,
    },
  },

  boxShadowBase: "0 5px 25px rgba(0,0,0,0.2)",
  boxShadowControls: "0 2px 5px rgba(0,0,0,0.1)",
  drawerWidth: 290,
  drawerClosedWidth: 58,
  topBarHeight: "60px",
  transitionBase: "all 0.5s 0s ease",

  headingMargins: "18px 0 30px 0",
};

export default theme;
