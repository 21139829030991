import { makeStyles } from '@material-ui/core';
import bgImage from '../../../../assets/images/logInbg.jpg';

const Styles = theme => ({
    container: {
        height: "100vh",
        width: "100vw",
        padding: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${bgImage})`,
    },
    card: {
        maxWidth: "800px",
        padding: "40px 5%",
        width: "80%",
    },
    cardContent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",

        "& $title": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: theme.spacing(3),
            "& img": {
                marginRight: theme.spacing(2),
                height: "auto",
                width: "32px",
            },
            "& h1": {
                margin: 0,
            },
        },
        "& $form": {
            marginTop: theme.spacing(3),
            width: "100%",
        },
        "& $formControl": {
            display: "block",
            marginBottom: theme.spacing(4),
        },
        "& $formError": {
            color: "#fff",
            borderRadius: 4,
            background: theme.palette.error.main,
            marginBottom: theme.spacing(3),
            padding: theme.spacing(3),
        },
        "& $formSuccess": {
            color: "#fff",
            borderRadius: 4,
            background: theme.palette.success.main,
            marginBottom: theme.spacing(3),
            padding: theme.spacing(3),
        },
    },
    form: {},
    formControl: {},
    formError: {},
    formSuccess: {},
    title: {},

});

const CenteredStyles = makeStyles(Styles);

export default CenteredStyles;