import React, { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import { CoreContext } from '../../../context/CoreContext';

import { logIn } from '../../../auth';
import { getActiveAppPath } from '../../../utils';

import LoginView from './View';
import jwtDecode from 'jwt-decode';

const Login = () => {
  const history = useHistory();
  const context = useContext(CoreContext);
  const [message, setMessage] = useState('');

  // TODO: Redirecto to default child app if user is already logged in

  const updateToken = ({ jwtToken, jwtTokenExpiry }) => {
    context.setToken({ jwtToken, jwtTokenExpiry });
  };

  // Spark only feature
  const setAppActions = applications => {
    let appActions = {};
    applications?.spark?.access && (appActions = applications.spark.actions);
    context.setAppActions(appActions);
  };

  const success = response => {
    const decodedJWT = jwtDecode(response.jwt_token);
    updateToken({
      jwtToken: response.jwt_token,
      jwtTokenExpiry: decodedJWT.exp
    });
    // disable entry for unauthorized users
    if (!response.applications.athome.access && !response.applications.spark.access) {
      setMessage('Your account doesn\'t have valid permissions');
      return;
    }

    // update app access and set app actions
    const appAccess = {
      'AtHome': response.applications.athome.access,
      'Spark': response.applications.spark.access,
    };
    const activeAppPath = getActiveAppPath(appAccess);
    context.setAppAccess(appAccess);
    setAppActions(response.applications);
    history.push(activeAppPath);
  };

  const error = (response) => {
    const _message = (response.status === 400) ? 'Email address was not found in our system' : 'Password is not valid';
    setMessage(_message);
  };

  const onSubmit = formData => {
    setMessage('');
    logIn({
      email: formData.email,
      password: formData.password,
    }).then(response => {
      !response.error ? success(response) : error(response);
    });
  };

  return <LoginView message={message} handleLoginSubmit={onSubmit} />;
};

export default Login;
