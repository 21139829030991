import React from "react";
import {
  Button,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";

import CenteredStyles from "../../../layouts/Centered/View/styles";
import { paths } from "../../../../constants";

const ForgotPasswordView = ({ message, messageType, handleForgotPasswordSubmit }) => {
  const { register, handleSubmit, errors } = useForm();

  const classes = CenteredStyles();

  return (
    <>
      <CardContent className={classes.cardContent}>
        <Grid item className={classes.title} xs={6}>
          <Typography component="h1" variant="h5">Forgot Password?</Typography>
        </Grid>
        <form className={classes.form} onSubmit={handleSubmit(handleForgotPasswordSubmit)}>
          {message !== '' && (<div className={messageType === 'success' ? classes.formSuccess : classes.formError}>{message}</div>)}
          <FormControl className={classes.formControl}>
            <TextField
              error={!!errors.email}
              name="email"
              type="email"
              placeholder="email@dickeys.com"
              fullWidth
              label="Email"
              inputRef={register({ required: true })}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {errors.email && <FormHelperText>Email is required</FormHelperText>}
          </FormControl>
          <Grid item xs={12}>
            <Grid container alignItems="center" justify="space-between">
              <Button type="submit">Send</Button>
              <Link component={RouterLink} to={paths.LOGIN}>Go back</Link>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </>
  );
};

export default ForgotPasswordView;
