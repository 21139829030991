import React, { useState } from "react";
import {
  Button,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useForm } from "react-hook-form";

import CenteredStyles from "../../../layouts/Centered/View/styles";

const ResetPasswordView = ({ message, messageType, handleResetPasswordSubmit }) => {
  const [showPassword, setShowPassword] = useState(false);
  //const [showPassword2, setShowPassword2] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const classes = CenteredStyles();

  return (
    <>
      <CardContent className={classes.cardContent}>

        {messageType === 'success' ?
          (<Grid item direction="column" className={classes.title} xs={12}>
            <br/>
            <Typography component="h1" variant="h4" cl>Password successfully reset!</Typography>
            <br/>
            <Typography component="h1" variant="h5">You may now use your new password to log in.</Typography>
          </Grid>) :
          (<>
            <Grid item className={classes.title} xs={6}>
              <Typography component="h1" variant="h5">Reset Password</Typography>
            </Grid>
            <form className={classes.form} onSubmit={handleSubmit(handleResetPasswordSubmit)}>
            {message !== '' && (<div className={messageType === 'success' ? classes.formSuccess : classes.formError}>{message}</div>)}
            <FormControl className={classes.formControl}>
              <TextField
                error={!!errors.password}
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="*********"
                label="Password"
                fullWidth
                inputRef={register({
                  required: 'Password is required',
                  minLength: {
                    value: 8,
                    message: 'Password must have at least 8 characters',
                  }
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((prevState) => !prevState)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password && (
                <FormHelperText>{errors.password.message}</FormHelperText>
              )}
            </FormControl>
            {/* <FormControl className={classes.formControl}>
            <TextField
              error={!!errors.password2}
              name="password2"
              type={showPassword2 ? "text" : "password"}
              placeholder="*********"
              label="Confirm Password"
              fullWidth
              inputRef={register({
                required: 'Confirm password is required',
                validate: value => {
                  console.log(passwordRef.current);
                  return value === passwordRef.current || 'The passwords do not match'
                }
              })}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password2 visibility"
                      onClick={() => setShowPassword2((prevState) => !prevState)}
                    >
                      {showPassword2 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.password2 && (
              <FormHelperText>{errors.password2.message}</FormHelperText>
            )}
          </FormControl> */}
            <Grid item xs={12}>
              <Grid container alignItems="center" justify="space-between">
                <Button type="submit">Reset</Button>
                {/*{messageType === 'success' && <Link component={RouterLink} to={paths.LOGIN}>Go to login page</Link>}*/}
              </Grid>
            </Grid>
            </form>
          </>)}
      </CardContent>
    </>
  );
};

export default ResetPasswordView;
