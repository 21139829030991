import config from '../config';
import { localState } from '../utils';
import { localStateKeys } from '../constants';
import jwtDecode from 'jwt-decode';

// Spark only feature
const setAppActions = (applications, context) => {
    let appActions = {};
    applications?.spark?.access && (appActions = applications.spark.actions);
    context.setAppActions(appActions);
};

const isLoggedIn = async (context) => {

    const { jwtToken, jwtTokenExpiry } = context.getToken();
    let token = jwtToken;

    const subMinutes = (dt, minutes) => {
        return new Date(dt.getTime() - minutes * 60000);
    };

    const silentRefresh = async () => {
        const response = await fetch(`${process.env.REACT_APP_LOGIN_API_URL}/refresh`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }).catch(err => console.log(err, 'err'));

        if (response.status === 401) {
            window.location.href = config.indexPath;
        }

        const authInfo = await response.json();

        const decodedJWT = jwtDecode(authInfo.jwt_token);

        context.setToken({ jwtToken: authInfo.jwt_token, jwtTokenExpiry: decodedJWT.exp * 1000 }); // unix times 1000 to milliseconds

        setAppActions(authInfo.applications, context);

        return authInfo.jwt_token;
    };

    if (jwtToken && window.location.pathname !== config.indexPath) {
        if (subMinutes(new Date(jwtTokenExpiry * 1000), 1) <= new Date()) {  // unix times 1000 to milliseconds
            token = await silentRefresh();
            return !!token;
        } else {
            return !!token;
        }
    } else if (window.location.pathname !== config.indexPath) {
        token = await silentRefresh();
        return !!token;
    } else {
        return !!token;
    }

};

const logIn = async ({ email, password }) => {
    const response = await fetch(`${process.env.REACT_APP_LOGIN_API_URL}/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({ email, password }),
    });

    if (!response.ok) return { error: true, status: response.status, statusText: response.statusText };

    const { jwt_token, applications } = await response.json();

    const decodedJWT = jwtDecode(jwt_token);

    return {
        jwt_token,
        jwt_token_expiry: decodedJWT.exp,
        applications
    }
};

const logOut = async context => {
    await fetch(`${process.env.REACT_APP_LOGIN_API_URL}/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        }
    });
    context.setToken({ jwtToken: null, jwtTokenExpiry: null });
    localState().set(localStateKeys.APP_ACCESS, {});
    localState().set(localStateKeys.APP_ACTIONS, {});
    localState().set(localStateKeys.JWT_TOKEN, '');
    localState().set(localStateKeys.JWT_TOKEN_EXPIRY, '');
};

const forgotPassword = async ({ email }) => {
    const response = await fetch(`${process.env.REACT_APP_LOGIN_API_URL}/forgotPassword`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({ email }),
    });

    if (!response.ok) return { status: response.status };

    const status = response.status;
    return {
        status
    }
};

const resetPassword = async ({ password, token }) => {
    const response = await fetch(`${process.env.REACT_APP_LOGIN_API_URL}/resetPassword`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({ password, token }),
    });

    if (!response.ok) return { status: response.status };

    const status = response.status;
    return {
        status
    }
};

export { forgotPassword, resetPassword, isLoggedIn, logIn, logOut };
