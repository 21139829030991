import React, { useState } from 'react';
import { CoreContext } from '../../../context/CoreContext';

import { forgotPassword } from '../../../auth';

import ForgotPasswordView from './View';

const ForgotPassword = () => {
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('error');

  const success = response => {
    let _message = '';
    
    switch(response.status) {
      case 200:
        _message = 'Please check your email. The instructions on how to reset a password has been sent';
        break;
      case 400:
        _message = 'This email address doesn\'t exist in our system';
        break;
      default:
        _message = 'Something went wrong with your request';
    } 

    const _messageType = (response.status !== 200) ? 'error' : 'success';
    setMessage(_message);
    setMessageType(_messageType);
  };

  const error = (response) => {    
    const _message = 'There has been an error with your request';
    setMessage(_message);
    setMessageType('error');
  };

  const onSubmit = formData => {
    setMessage('');
    forgotPassword({
      email: formData.email,
    }).then(response => {
      !response.error ? success(response) : error(response);
    });
  };

  return <ForgotPasswordView message={message} messageType={messageType} handleForgotPasswordSubmit={onSubmit} />;
};

export default ForgotPassword;