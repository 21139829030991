import { makeStyles } from '@material-ui/core';

const Styles = theme => ({

    root: {
        "& $icon": {
            marginRight: theme.spacing(2),
            padding: "12px 0",
            color: theme.colors.iconButtonText,
            "&:hover": {
                background: "transparent",
            }
        },
    },

    menu: {
        "& $item": {
            display: "block",
            fontSize: theme.typography.fontSizeS,
            padding: "6px 16px",
        },
    },
    icon: {},
    item: {},
});

const AppSwitcherStyles = makeStyles(Styles);

export default AppSwitcherStyles;
