import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
    container: {
        background: '#fff',
    },
});

const BlankStyles = makeStyles(Styles);

export default BlankStyles;