import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import jwtDecode from 'jwt-decode'
import PropTypes from 'prop-types';
import TopBarView from './View';

import useAuth from '../../../../auth/useAuth';
import { CoreContext } from '../../../../context/CoreContext';

import config from '../../../../config';

const TopBar = ({ handleDrawerOpen }) => {
  const context = useContext(CoreContext);
  const activeModule = context.getActiveModule();

  const [userMenu, setUserMenu] = useState(null);
  const [userName, setUserName] = useState('');
  const { logout } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const token = context.getToken();
    if (token !== null) {
      const user = jwtDecode(token.jwtToken);
      user && setUserName(user.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrawerOpenClick = actionType => {
    handleDrawerOpen(actionType);
  };

  const handleHelpClick = () => {
    history.push(config.helpPath);
  }

  const handleLogout = () => {
    logout();
  };

  const handleUserMenu = el => {
    el !== null ? setUserMenu(el) : setUserMenu(null);
  };

  return (
    <TopBarView
      activeModule={activeModule}
      handleDrawerOpen={handleDrawerOpenClick}
      handleHelpClick={context.getAppName() === "Spark" && handleHelpClick}
      handleLogout={handleLogout}
      handleUserMenu={handleUserMenu}
      userMenu={userMenu}
      userName={userName}
    />
  );
};

TopBar.propTypes = {
  handleDrawerOpen: PropTypes.func.isRequired,
};

export default TopBar;
