import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Drawer, Hidden } from '@material-ui/core';

import WithSideBarStyles from './styles';
import TopBar from '../../includes/TopBar';
import SideBar from '../../includes/SideBar';

import { CoreContext } from '../../../../context/CoreContext';

const WithSideBarView = ({ children, handleSideBarOpen }) => {
  const classes = WithSideBarStyles();
  const { getSidebarOpen, getSidebarOpenMobile } = useContext(CoreContext);

  const sidebarOpen = getSidebarOpen();
  const sidebarOpenMobile = getSidebarOpenMobile();

  const sidebarClosedClassName = !sidebarOpen ? 'closed' : '';

  return (
    <div className={`${classes.root} ${sidebarClosedClassName}`}>
      <div className={classes.mainColumn}>
        <div className={classes.topbar}>
          <TopBar handleDrawerOpen={handleSideBarOpen} />
        </div>
        <main className={classes.content}>
          <div className={classes.contentInner}>{children}</div>
        </main>
      </div>
      <div className={classes.sidebar}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            open={sidebarOpenMobile}
            onClose={() => handleSideBarOpen('mobile')}
            classes={{
              paper: classes.drawerPaperMobile,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <SideBar variant="mobile" />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css" className={classes.drawerContainer}>
          <Drawer
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: sidebarOpen,
              [classes.drawerClose]: !sidebarOpen,
            })}
          >
            <SideBar variant="desktop" />
          </Drawer>
        </Hidden>
      </div>
    </div>
  );
};

WithSideBarView.propTypes = {
  children: PropTypes.object.isRequired,
  handleSideBarOpen: PropTypes.func.isRequired,
};

export default WithSideBarView;
