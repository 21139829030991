import React from 'react';
import PropTypes from 'prop-types';
import CenteredStyles from './styles';
import { Container, Card } from '@material-ui/core';

const CenteredView = ({ children }) => {
  const classes = CenteredStyles();
  
  return (
    <Container maxWidth={false} className={classes.container}>
      <Card className={classes.card}>{children}</Card>
    </Container>
  );
};

CenteredView.propTypes = {
  children: PropTypes.object.isRequired,  
};

export default CenteredView;
