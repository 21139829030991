import { Link } from 'react-router-dom';

const createBreadcrumbLink = (link, pathname) => {
	const splitedPathname = pathname.split('/');

	return link.split('/').reduce((result, path, index) => result.concat(path.includes(':') 
		? `/${splitedPathname[index]}` 
		: `/${path}`), ['']).join('').replace(/\/\//g, '/');
}
  
export const renderBreadcrumbs = (breadcrumbs, history, classes) => (
	<>
		{breadcrumbs.map(({ title = '', link = ''}, index) => (
				<Link 
					className={classes.breadcrumbsLink} 
					to={createBreadcrumbLink(link, history.location.pathname)}
					key={`${title}-${link}`}
				>        
					{index > 0 ? ' | ' : ''}{title}
				</Link>
			))
		}
	</>
);