import React, { useContext } from 'react';
import NavListView from './View';

import { CoreContext } from '../../../../context/CoreContext';

const NavList = ({ variant }) => {
  const context = useContext(CoreContext);
  const modules = context.getSidebarModules() || [];  
  const activeModule = context.getActiveModule();
  const appActions = context.getAppActions();
  const appName = context.getAppName();
  const isSidebarOpen = context.getSidebarOpen();

  return (
    <NavListView activeModule={activeModule} appActions={appActions} appName={appName} modules={modules} isSidebarOpen={isSidebarOpen} variant={variant} />
  );
};

export default NavList;
