import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { CoreContext } from '../../../context/CoreContext';

import WithSideBarView from './View';

const WithSideBar = ({ children, metaTitle }) => {
  const { setSidebarOpen, setSidebarOpenMobile } = useContext(CoreContext);

  useEffect(() => {
    document.title = metaTitle || '';
  }, [metaTitle]);

  const handleSideBarOpen = actionType => {
    actionType === 'mobile' ? setSidebarOpenMobile() : setSidebarOpen();    
  };

  return (
    <WithSideBarView 
      children={children} 
      handleSideBarOpen={handleSideBarOpen} 
    />
  );
};

WithSideBar.propTypes = {
  children: PropTypes.object.isRequired,
  metaTitle: PropTypes.string,
};

export default WithSideBar;
