const paths = {
  FORGOT_PASSWORD: '/forgotPassword',
  LOGIN: '/login',
  HELP: '/spark/help',
  RESET_PASSWORD: '/resetPassword/:token',
  APPS: {
    DEFAULT: '/spark',
    SPARK: '/spark',
    AT_HOME: '/at-home',
  }
};

export default paths;
