import { paths } from '../../constants';
import { SparkApp } from './index';
import dickeysLogo from '../../assets/images/dickeysLogo.png';

const config = {
  appIcon: dickeysLogo,
  appName: 'Spark',
  appTitle: 'Spark Dashboard',
  appPath: paths.APPS.SPARK,  
  modules: [
    {
      id: 'spark-dashboard',
      component: SparkApp,
      label: 'Spark Dashboard',
      metaTitle: 'Spark Dashboard',
      path: paths.APPS.SPARK,
      isPublic: false,
      exact: false,
    }
  ],
};

export default config;