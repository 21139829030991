import React from "react";
import { CircularProgress, Icon, List } from "@material-ui/core";

import SideBarStyles from "./styles";
import NavList from "../../NavList";
import AppSwitcher from "../../AppSwitcher";

const SideBarView = ({ appSwitching, icon, title, variant }) => {
  const classes = SideBarStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {appSwitching && <div className={classes.progress}>
          <CircularProgress className={classes.progressCircle} />        
        </div>}
        <Icon className={classes.logo}>
          <img alt="" src={icon} />
        </Icon>
        <h1 className={classes.title}>{title}</h1>
        <AppSwitcher className={classes.icon} />
      </div>
      <List className={classes.list}>
        <NavList variant={variant} />
      </List>
    </div>
  );
};

export default SideBarView;
