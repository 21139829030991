import React, { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import AppSwitcherView from './View';

import { CoreContext } from '../../../../context/CoreContext';

import { getChildConfigByName } from '../../../../utils';

const AppSwitcher = () => {

    const history = useHistory();
    const [anchor, setAnchor] = useState(null);

    const context = useContext(CoreContext);
    const appAccess = context.getAppAccess();

    const checkAppAccess = () => {
        return (appAccess['AtHome'] && appAccess['Spark']);
    };

    const switchApp = appName => {        
        const childConfig = getChildConfigByName(appName);
        const appPath = childConfig.appPath;
        history.push(appPath);
        context.setAppSwitching(true);
        context.setActiveModule({});
    };

    const handleButtonClick = e => {
        checkAppAccess() && setAnchor(e.currentTarget);
    };

    const handleItemClick = appName => {
        switchApp(appName);
        handleClose();
    };

    const handleClose = () => {
        setAnchor(null);
    };

    return checkAppAccess() ? (
        <AppSwitcherView
            anchor={anchor}            
            handleButtonClick={handleButtonClick}
            handleItemClick={handleItemClick}
            handleClose={handleClose} 
        />
    ) : (<></>);
};

export default AppSwitcher;
