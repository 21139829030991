import React, { useContext } from 'react';
import SideBarView from './View';

import { CoreContext } from '../../../../context/CoreContext';
import { getChildConfigByName } from '../../../../utils';

const SideBar = ({ variant }) => {
  const context = useContext(CoreContext);
  const appName = context.getAppName() || ''; 
  const appSwitching = context.getAppSwitching() || false;

  const config = getChildConfigByName(appName);
  const icon = config.appIcon;
  const title = config.appTitle;
  
  return (
    <SideBarView appSwitching={appSwitching} icon={icon} title={title} variant={variant} />
  );
};

export default SideBar;
