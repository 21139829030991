import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import AppSwitcherStyles from './styles';

const AppSwitcherView = ({ anchor, handleButtonClick, handleItemClick, handleClose }) => {
    const classes = AppSwitcherStyles();

    return (
        <div className={classes.root}>
            <IconButton 
                aria-controls="app-switcher" 
                aria-haspopup="true" 
                className={classes.icon}
                disableRipple
                onClick={handleButtonClick}
            >
                <KeyboardArrowDownIcon />
            </IconButton>
            <Menu
                anchorEl={anchor}
                className={classes.menu}
                id="app-switcher"
                keepMounted
                open={anchor !== null}
                onClose={handleClose}
            >
                <MenuItem className={classes.item} onClick={() => handleItemClick('Spark')}>Spark Dashboard</MenuItem>
                <MenuItem className={classes.item} onClick={() => handleItemClick('AtHome')}>At Home Dashboard</MenuItem>
            </Menu>
        </div>
    );
};

export default AppSwitcherView;
