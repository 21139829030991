import ForgotPassword from './ForgotPassword';
import Login from './Login';
import NotFound from './NotFound';
import ResetPassword from './ResetPassword';

export const pages = {
  ForgotPassword,
  Login,
  NotFound,
  ResetPassword,
};
