import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      height: '100vh',
    },
  },
  mainColumn: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      order: 2,
      flexShrink: 1,
      flexGrow: 1,
      overflow: "hidden",
    },
  },
  sidebar: {
    [theme.breakpoints.up('md')]: {
      order: 1,
      flexShrink: 0,
    },
  },
  drawerContainer: {
    position: 'relative',
    height: '100%',
    zIndex: 100,
    boxShadow: theme.boxShadowBase,
  },
  drawer: {
    height: '100%',
    width: theme.drawerWidth,
    overflow: 'hidden',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpen: {
    width: theme.drawerWidth,
  },
  drawerClose: {
    width: theme.drawerClosedWidth,
  },
  drawerPaper: {
    width: theme.drawerWidth,
    border: 'none',
    position: 'relative',
    overflow: 'hidden',
  },
  drawerPaperMobile: {
    width: theme.drawerWidth,
    border: 'none',
  },
  topbar: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  content: {
    display: "flex",
    flexGrow: 1,
    overflow: "hidden",    
  },
  contentInner: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowY: "auto",    
  },
});

const WithSideBarStyles = makeStyles(Styles);

export default WithSideBarStyles;
